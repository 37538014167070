<template>
  <div class="containertop-addressEdit">
    <div>
      <nav-bar
        :title="'新增收貨地址'"
        :goBack="goBack"
        :bgc="'background:#fff'"
        :isFixed="true"
      ></nav-bar>
      <div :class="['addressEdit-box',{headerTop:!isWeiXin()}]">
        <div class="addressEdit-form">
          <van-form @submit="onSubmit">
            <div class="label" style="margin-top: 0;">
              {{ $t("收件人") }}  <span class="verify">*</span>
            </div>
            <van-field v-model="params.name" :placeholder="$t('收件人')" />
            <div class="error" v-if="verifyobj.name">{{ $t("請輸入") }}{{ $t("收件人") }}</div>

            <div class="label">{{ $t("所在地區") }} <span class="verify">*</span></div>
            <div class="AddressList" @click="openAddressDialog">
              <div :style="selectTitle ? 'color:#1A1A1A;' : ''">
                {{ selectTitle ? selectTitle : $t("所在地區") }}
              </div>
              <img src="@/assets/icons/arrow-rightactive.png" alt="" />
            </div>
            <div
              class="error"
              v-if="verifyobj.province"
              style="margin-top: 6px;"
            >
           {{ $t("請選擇") }}{{ $t("所在地區") }}
            </div>

            <div class="label">{{ $t('街道/門牌') }}<span class="verify">*</span></div>
            <van-field
              style="margin-bottom: 12px;margin-top: 12px;"
              v-model="params.street"
              :placeholder="$t('街道/門牌')"
            />
            <div class="error" v-if="verifyobj.street">
              {{ $t("請輸入") }}{{ $t("街道/门牌") }}
            </div>
            <div class="label">{{ $t("詳細地址") }} <span class="verify">*</span></div>
            <van-field
              v-model="params.address"
              type="textarea"
              :placeholder="$t('詳細地址')"
            />
            <div class="error" v-if="verifyobj.address">
              {{ $t("請輸入") }}{{ $t("詳細地址") }}
            </div>
            <div class="label">{{ $t("手機號") }} <span class="verify">*</span></div>
            <div class="phone" @click="show = true">
              <span>{{ phoneLabel }}</span>
              <img
                class="arrow"
                src="@/assets/icons/arrow-rightactive.png"
                alt=""
              />
            </div>
            <van-field
              v-model="params.phone"
              type="text"
              :placeholder="$t('手機號')"
            />

            <div class="error" v-if="verifyobj.phone || verifyobj.phoneregx">
              {{ $t("請輸入") }}{{ $t("正確手機號碼") }}
            </div>
          </van-form>
        </div>
        <div class="addressEdit-default">
          <div>
            <div class="addressEdit-settings">{{ $t("設爲默認地址") }}</div>
            <div class="addressEdit-order">{{ $t("下單時，默認該地址收貨") }}</div>
          </div>
          <div>
            <van-switch
              size="24px"
              v-model="params.isDefault"
              active-color="#90D12E"
              inactive-color="#BDBDBD"
            />
          </div>
        </div>
        <div class="address-footer" @click="onSubmit">
          <div class="address-btn">{{ $t("提交") }}</div>
        </div>
      </div>
    </div>
    <addressCascader
      :isShow="isShow"
      :echoList="echoList"
      @handleClose="handleClose"
      @handleAreaCode="handleAreaCode"
    ></addressCascader>
    <van-popup
      v-model:show="show"
      position="bottom"
      :style="{ height: '40%', borderRadius: '16px 16px 0 0' }"
    >
      <div class="titleArea">
        <div></div>
        <div>{{ $t("國家地區") }}</div>
        <div class="close">
          <img
            @click="show = false"
            style="width: 100%"
            src="@/assets/icons/ic_Close@2x.png"
            alt=""
          />
        </div>
      </div>
      <div class="phone-list">
        <div
          :class="['phone-item', { actvie: item.code == selectPhone }]"
          v-for="item in phoneCode"
          :key="item.value"
          @click="handlePhoneCode(item)"
        >
          <div class="phone-text">{{ item.name }}</div>
          <img
            class="phone-check"
            v-if="item.code == selectPhone"
            src="@/assets/icons/check.png"
            alt=""
          />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  getCaptchaNew,
  getAddedShippingAddress,
  getEditPhoneAreaCode
} from "@/api/user";
import { mapState } from "vuex";
import mixins from "@/utils/mixins.js";
export default {
  beforeRouteLeave(to,form,next){
    window.localStorage.removeItem("addressEditList")
    next()
  },
  mixins: [mixins],
  data() {
    return {
      fileList: [],
      params: {
        province: "",
        city: "",
        district: "",
        phone: "",
        name: "",
        isComplete: true,
        address: "",
        street: "",
        areaCode: "+852",
        isDefault: false
      },
      active: "1",
      isPassword: true,
      isEmail: false,
      isEyes: false,
      AddressList: [],
      isShow: false,
      selectTitle: "",
      checked: false,
      verifyobj: {
        street: "",
        address: "",
        name: "",
        phone: "",
        province: ""
      },
      verifyList: [],
      isRegister: false,
      isCaptchaNew: true,
      CaptchaNewCode: "",
      countDown: 60,
      loading: false,
      phoneLabel: "HK(+852)",
      show: false,
      columns: [
        {
          value: "1",
          label: "HK(+852)"
        },
        {
          value: "2",
          label: "MO(+853)"
        }
      ],
      selectPhone: "1",
      echoList: []
    };
  },
  computed: {
    ...mapState("user", ["phoneCode"])
  },
  async created() {
    // this.getAddressList();
    await this.$store.dispatch("user/getPhoneCode");
    this.selectPhone = this.phoneCode[0]?.code;
    this.phoneLabel = this.phoneCode[0]?.name;
    if (this.$route.query.edit) {
      const StorageList = JSON.parse(
        window.localStorage.getItem("addressEditList")
      );
      if (StorageList) {
        this.params.name = StorageList.name;
        this.params.phone = StorageList.phone;
        this.params.street = StorageList.street;
        this.params.isDefault = StorageList.isDefault;
        this.params.province = StorageList.province;
        this.params.city = StorageList.city;
        this.params.areaCode = StorageList.areaCode;
        this.params.address = StorageList.address;
        this.params.district = StorageList.district;
        this.params.id = StorageList.id;
        const arr = [];
        arr.push(StorageList.province.split("●")[1]);
        arr.push(StorageList.city.split("●")[1]);
        arr.push(StorageList.district.split("●")[1]);

        this.selectTitle = arr.filter(item => item).join("/");
      }
    }
  },
  methods: {
    handlePhoneCode(item) {
      this.selectPhone = item.code;
      this.show = false;
      this.params.areaCode = item.code;
      this.phoneLabel = item.name;
    },
    goBack() {
      if (!this.isCaptchaNew) {
        this.isCaptchaNew = true;
      } else {
        this.$router.back();
      }
    },
    gotoRegister() {
      this.isRegister = false;
      this.checked = true;
    },
    beforeRead(file) {
      console.log(file);

      const maxSize = 2 * 1024 * 1024;
      // if (!file.type?.includes('image')) {
      //   this.$toast("請上傳圖片");
      //   return false;
      // }
      if (file.size > maxSize) {
        this.$toast("文件體積過大");
        return false;
      }
      return true;
    },
    validatoFormFormcheck(label) {
      if (!this.params[label]) {
        this.verifyList.push(label);
      } else {
        const index = this.verifyList.findIndex(item => item === label);
        if (index != -1) {
          this.verifyList.splice(index, 1);
        }
      }
    },
    validatoForm() {
      for (const key in this.verifyobj) {
        this.verifyobj[key] = "";
      }

      this.verifyList.forEach(item => {
        this.verifyobj[item] = true;
      });
    },
    gotoLogin() {
      this.$router.push("/login");
    },
    afterRead(file) {
      this.params.businessLicense = file.file;
      console.log(file);
    },
    handleAreaCode(data) {
      const areaList = data.filter(item => item.value !== "1");

      
      areaList.forEach((item, index) => {
        if (index === 0) {
          this.params.province = item.value + "●" + item.label;
          this.params.district = '';
          this.params.city = '';
        } else if (index === 1) {
          this.params.city = item.value + "●" + item.label;
        } else if (index === 2) {
          this.params.district = item.value + "●" + item.label;
        }
      });
      const res = areaList.map(items => items.label).join("/");
      this.selectTitle = res;
    },
    async onSubmit() {
      const phoneRegex = /^([5|6|8|9])\d{7}$/;
      const regx = /^(?=.*\d)(?=.*[a-zA-Z]).+$/;
      this.verifyList = [];
      this.params.areaCode = this.selectPhone;
      if (!phoneRegex.test(this.params.phone)) {
        this.verifyobj["phoneregx"] = true;
      } else {
        delete this.verifyobj["phoneregx"];
      }

      const keys = Object.keys(this.verifyobj);
      keys.forEach(item => {
        this.validatoFormFormcheck(item);
      });

      this.validatoForm();

      if (this.verifyList.length > 0) {
        return;
      }
      const res = this.$route.query.edit
        ? await getEditPhoneAreaCode(this.params)
        : await getAddedShippingAddress(this.params);
      if (res.resultID === 1200) {
        this.$toast(this.$t('成功'));
        this.$router.back();
      }
    },
    openAddressDialog() {
      const StorageList = JSON.parse(
        window.localStorage.getItem("addressEditList")
      );
      if (StorageList) {
        this.echoList.push(StorageList.province.split("●")[0]);
        this.echoList.push(StorageList.city.split("●")[0]);
        this.echoList.push(StorageList.district.split("●")[0]);
      }
      console.log(this.echoList);

      this.isShow = true;
    },
    handleClose(data) {
      this.echoList = [];
      this.isShow = false;
    }
  }
};
</script>

<style lang="scss">
.containertop-addressEdit {
  .addressEdit-box {
    padding: 16px 10px;
    padding-bottom: 80px;
  }
  .email-CaptchaNew {
    padding: 40px 26px;
    .CaptchaNew-title {
      font-size: 15px;
      color: #666666;
      line-height: 24px;
      margin-bottom: 24px;
    }
    .get-CaptchaNew {
      text-align: center;
      padding: 10px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #d2edab;
      color: #bce382;
      margin-bottom: 40px;
    }
    .get-CaptchaNew-btn {
      width: 100%;
      background: #90d12e;
      border-radius: 8px 8px 8px 8px;
      color: #fff;
    }
  }
  .bg {
    height: 210px;
    background: linear-gradient(
      180deg,
      rgba(144, 209, 46, 0.3) 0%,
      rgba(144, 209, 46, 0) 100%
    );
    border-radius: 0px 0px 0px 0px;
  }
  .logo {
    width: 166px;
    margin: 46px 0 40px 32px;
  }
  .addressEdit-form {
    padding: 12px 16px;
    padding-bottom: 53px;
    background-color: #fff;
    border-radius: 12px;
    margin-top: 16px;
    .label {
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 17px;
      color: #1a1a1a;
      line-height: 24px;
      margin-top: 24px;
    }
    .van-checkbox {
      align-items: start;
      .van-checkbox__icon {
        margin-top: 3px;
        .van-badge__wrapper {
          border-radius: 2px;
        }
      }
    }
    .agree {
      font-size: 14px;
      .agree-text {
        color: #90d12e;
      }
    }
    .van-uploader,
    .van-uploader__input-wrapper {
      width: 100%;
      .van-uploader__preview-image {
        height: 216px !important;
        object-fit: cover;
      }
    }
    .van-button {
      border-radius: 12px;
    }
    .error {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #f82207;
      line-height: 18px;
      padding-left: 12px;
      margin-top: 1px;
    }
  }
  .verify {
    color: #f82207;
  }
  .van-field {
    background-color: #fff;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #ebebeb;
    margin-bottom: 12px;
    padding: 16px;
    .van-field__control {
      font-size: 16px;
      &::placeholder {
        color: #999999;
      }
    }
  }
  .van-tabs {
    width: 60%;
  }
  .van-tabs__nav {
    background-color: transparent;
    height: auto;
    .van-tabs__line {
      display: none;
    }
    .van-tab:first-child {
      border-right: 1px solid #bdbdbd;
    }
    .van-tab__text {
      font-size: 18px;
    }
  }
  .arrow {
    width: 16px;
    height: 16px;
  }

  .login-form {
    padding: 0 22px;
  }
  .addressEdit-default {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border-radius: 12px 12px 12px 12px;
    margin-top: 12px;
    padding: 12px 16px;
    .addressEdit-settings {
      font-weight: 500;
      font-size: 17px;
      color: #1a1a1a;
      margin-bottom: 4px;
    }
    .addressEdit-order {
      font-size: 13px;
      color: #666666;
    }
  }
}
.phone {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #1a1a1a;
  margin: 12px 0px 20px 14px;
  line-height: 1;
}
.Forgot {
  display: flex;
  justify-content: end;
  font-size: 15px;
  color: #1a1a1a;
  line-height: 24px;
  margin-top: 20px;
}
.register {
  text-align: center;
  color: #90d12e;
}
.AddressList {
  width: 100%;
  border-radius: 12px 12px 12px 12px;
  border: 1px solid #ebebeb;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999999;
  font-size: 16px;
  background-color: #fff;
  // margin-bottom: 6px;
  img {
    width: 20px;
  }
}
.register-dialog {
  padding: 24px;
}
.register-text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 15px;
  color: #666666;
  line-height: 24px;
  text-align: center;
  span {
    color: #3491fa;
  }
}
.register-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .Cancel,
  .Confirm {
    flex: 1;
    text-align: center;
    margin-top: 32px;
  }
  .Cancel {
    background: #eeeeee;
    border-radius: 12px 12px 12px 12px;
    padding: 8px;
    margin-right: 12px;
  }
  .Confirm {
    font-size: 16px;
    color: #ffffff;
    background-color: #90d12e;
    border-radius: 12px 12px 12px 12px;
    padding: 8px;
  }
}
.address-footer {
  background: #fff;
  padding: 9px 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  .address-btn {
    background: #90d12e;
    border-radius: 8px 8px 8px 8px;
    padding: 10px;
    text-align: center;
    color: #fff;
  }
}
.titleArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px 0;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 24px;

  .close {
    width: 20px;
  }
}
.phone-list {
  padding: 20px 16px;
  .actvie {
    border: 2px solid #90d12e !important;
  }
  .phone-item {
    padding: 20px;
    position: relative;
    background: #f8f8f8;
    border-radius: 8px 8px 8px 8px;
    margin-bottom: 12px;
    border: 2px solid transparent;
    .phone-text {
      font-size: 16px;
      color: #1a1a1a;
      line-height: 16px;
      text-align: center;
    }
    .phone-check {
      position: absolute;
      width: 24px;
      bottom: -1px;
      right: -1px;
    }
  }
}
</style>
